// Gallery

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
}
.gallery-item {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
}

figure.image {
    margin-bottom: $grid-gutter-width;

    img,
    picture {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
}

.figure-caption {
    padding-top: 0.375rem;

    @include media-breakpoint-up('lg') {
        font-size: $figure-caption-font-size-lg;
    }
}


// Gallery Item Sizes

.gallery-item-size-1 {
    width: 100%;
}
.gallery-item-size-2 {
    width: calc(100% / 2);
}
.gallery-item-size-3 {
    width: calc(100% / 3);
}
.gallery-item-size-4 {
    width: calc(100% / 2);
    @include media-breakpoint-up('sm') {
        width: calc(100% / 4);
    }
}

