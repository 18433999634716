// stylelint-disable selector-list-comma-newline-after

//
// Headings
//

h3, .h3 {
    font-size: $h3-font-size;
    font-weight: 600;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: $h3-font-size-lg;
    }
}

h4, .h4 {
    font-size: $h4-font-size;
    font-weight: 600;
    @include media-breakpoint-up(lg) {
        font-size: $h4-font-size-lg;
    }
}

h5, .h5 {
    font-size: $h5-font-size;
    font-weight: 600;
    @include media-breakpoint-up(lg) {
        font-size: $h5-font-size-lg;
    }
}


//
// Horizontal rules
//

hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

mark,
.mark {
    padding: $mark-padding;
    background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
    @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled();
}
.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}
