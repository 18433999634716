@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$colors: map-remove($colors, "indigo", "purple", "pink", "red", "orange", "yellow", "green", "cyan");
$theme-colors: map-remove($theme-colors, "success", "info", "warning");

//Bootstrap Optional
//@import "bootstrap/scss/root";
//@import "bootstrap/scss/reboot";
//@import "bootstrap/scss/type"; --> custom
@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
//@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";

//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";
// Custom Styles
@import "type";
@import "gallery";
@import "magnific";

body {
    @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
    }
}


.btn {
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: $btn-font-size-lg;
        padding: $btn-padding-lg-y $btn-padding-lg-x;
    }
}

.btn-gradient {
    color: $white;
    background: transparent $bg-gradient 0 0 no-repeat;

    @include hover() {
        color: $white;
    }
}
.btn-currency {
    position: relative;
    max-width: 100%;
    font-size: 2rem;
    padding: 9px 2.375rem 0.45rem;

    &:before {
        content:'€';
        position: absolute;
        left: 0;
        top: -0.65rem;
        opacity: 0.21;
        font-size: 5.25rem;
        line-height: 1;
    }

    @include media-breakpoint-up(lg) {
        font-size: 2rem;
        padding: 8px 3.375rem 0.325rem;

        &:before {
            top: -0.75rem;
        }
    }
}

.btn-link {
    text-transform: none;
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}

// Layout
.container {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

// Footer
.footer {
    background-color: $primary;
    font-size: 0.875rem;
    padding: 1.5rem 0;

    @include media-breakpoint-up(sm) {
        font-size: 1rem;
    }


    .list-inline-item:not(:last-child) {
        margin-right: 1rem;

        @include media-breakpoint-up(sm) {
           margin-right: 2rem;
        }
        @include media-breakpoint-up(lg) {
            margin-right: 2.875rem;
        }
    }

    a {
        color: $light;

        &:hover {
            color: darken($light, 5%);
        }
    }
}


